import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function SayYouWontLetGo() {
  return (
    <Layout>
      <Helmet title="Say You Won't Let Go | James Arthur" />

      <h1>Say You Won't Let Go</h1>

      <h2>James Arthur</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 116</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>I met you in the dark, you lit me up</p>
          <p>You made me feel as though I was enough</p>
          <p>We danced the night away, we drank too much</p>
          <p>I held your hair back when</p>
          <p>You were throwing up</p>
        </Verse>
        <Verse>
          <p>Then you smiled over your shoulder</p>
          <p>For a minute, I was stone-cold sober</p>
          <p>I pulled you closer to my chest</p>
          <p>And you asked me to stay over</p>
          <p>I said, I already told ya</p>
          <p>I think that you should get some rest</p>
        </Verse>
        <PreChorus>
          <p>I knew I loved you then</p>
          <p>But you'd never know</p>
          <p>'Cause I played it cool when I was scared of letting go</p>
          <p>I know I needed you</p>
          <p>But I never showed</p>
          <p>But I wanna stay with you until we're grey and old</p>
        </PreChorus>
        <Chorus>
          <p>Just say you won't let go</p>
          <p>Just say you won't let go</p>
        </Chorus>
        <Verse>
          <p>I'll wake you up with some breakfast in bed</p>
          <p>I'll bring you coffee with a kiss on your head</p>
          <p>And I'll take the kids to school</p>
          <p>Wave them goodbye</p>
          <p>And I'll thank my lucky stars for that night</p>
        </Verse>
        <Verse>
          <p>When you looked over your shoulder</p>
          <p>For a minute, I forget that I'm older</p>
          <p>I wanna dance with you right now</p>
          <p>Oh, and you look as beautiful as ever</p>
          <p>And I swear that everyday you'll get better</p>
          <p>You make me feel this way somehow</p>
        </Verse>
        <PreChorus>
          <p>I'm so in love with you</p>
          <p>And I hope you know</p>
          <p>Darling, your love is more than worth its weight in gold</p>
          <p>We've come so far, my dear</p>
          <p>Look how we've grown</p>
          <p>And I wanna stay with you until we're grey and old</p>
        </PreChorus>
        <Chorus>
          <p>Just say you won't let go</p>
          <p>Just say you won't let go</p>
        </Chorus>
        <Verse>
          <p>I wanna live with you</p>
          <p>Even when we're ghosts</p>
          <p>'Cause you were always there for me when I needed you most</p>
        </Verse>
        <Verse>
          <p>I'm gonna love you 'til</p>
          <p>My lungs give out</p>
          <p>I promise 'til death we part like in our vows</p>
        </Verse>
        <PreChorus>
          <p>So I wrote this song for you, now everybody knows</p>
          <p>That it's just you and me 'til we're grey and old</p>
        </PreChorus>
        <Chorus>
          <p>Just say you won't let go</p>
          <p>Just say you won't let go</p>
          <p>Just say you won't let go</p>
          <p>Oh, just say you won't let go</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
